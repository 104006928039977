import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ContentDialog from "../EditBusinessUnit/dialog";
import { getAllPagesContent, getModuleContent, deleteResourcesLinks, toastTriggered } from '../../redux/actions/index';
import AddNewResouces from './addNewResources';
import DisplayOrder from './displayOrder.js';
import DataTable from "../../component/dataTable/index";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import utils from '../../utils/index';
import { PlusCircleFill } from "../../assets/icons/Icons";
import toastProperties from "../../assets/data/toastProperty";


const Resources = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getPageContent = useSelector((state) => state.getPageContent);
  const oktaAuth = useSelector((state) => state.oktaAuth);
  const { activeRegion, activePage } = useSelector((state) => state.activeDynamicData);

  const [updateLinks, setUpdateLinks] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setopenDialog] = useState(false);
  const [selRowData, setSelRowData] = useState({});

  const { data, loading } = getPageContent;

  useEffect(() => {
    if (!!getPageContent.error) {
      setErrorMessage(getPageContent.userMessage)
    }
  }, [getPageContent]);

  useEffect(() => {
    if (activeRegion && activePage && activePage.key !== "Playbooks") {
      const params = {
        pageType: activePage.key,
        region: activeRegion,
      }
      dispatch(getModuleContent(params));
    }
  }, [dispatch, activePage, activeRegion]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      data.forEach((item, index) => {
        item.id = index + 1;
        return data;
      });
    }
  }, [data]);

  const renderModuleTitle = () => {
    if(displayOrder){
      return (
        <span>Update Positions</span>
      )
    }
    else if (updateLinks) {
      return (
        <span>Update Resource Links</span>
      )
    }
    else {
      return (
        <span>Create New Resource Links</span>
      )
    }
  };

  const closeDialog = () => {
    setopenDialog(false);
  };

  const renderModuleContent = () => {
    if(displayOrder && !updateLinks){
      return (
        <DisplayOrder
          rowDetails={selRowData}
          updateLinks={false}
          displayOrder={displayOrder}
          closeDialog={closeDialog}
          moduleData={data}
        />
      )
    }
    else{
      return (
        <AddNewResouces
          rowDetails={selRowData}
          updateLinks={updateLinks}
          closeDialog={closeDialog}
          moduleType={'linksTraining'}
        />
      )
    }
  };

  const renderContentDialog = () => {
    return (
      <ContentDialog
        contentTitle={
          renderModuleTitle()
        }
        openDialog={openDialog}
        closeDialog={closeDialog}
        moduleContent={
          renderModuleContent()
        }
      />
    )
  };

  const style = {
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#f8af2a',
    fontStyle: 'italic'
  };

  const deleteSelResources = (dataIndex, rowData) => {
    let rowIndex = rowData.rowIndex;
    let selectedRow = data[rowIndex];
    let text = `Are You Sure You Want to Delete the user ${selectedRow['displayName']}   Parmanently? This Action Can't be UNDONE`;
    if (window.confirm(text)) {
      let params = {linkId : selectedRow.LinkId}
      dispatch(deleteResourcesLinks(params)).then((res) => {
        toastProperties.success["description"] = res.data.message;
        dispatch(toastTriggered([toastProperties.success]));
        const params = {
          pageType: activePage.key,
          region: activeRegion,
        }
        dispatch(getAllPagesContent(params));
      })
        .catch((err) => {
          toastProperties.error["description"] = `There is some problem while deleting the user details in region ${activeRegion}. Please try again in some time.`;
          dispatch(toastTriggered([toastProperties.error]));
        });
    }
  };

  const updateLinksDetails = (dataIndex, rowData) => {
    let rowIndex = rowData.rowIndex;
    let selectedRow = data[rowIndex];
    console.log('NewResources', selectedRow, data, loading);
    setUpdateLinks(true)
    setopenDialog(true);
    setSelRowData(selectedRow);
    setDisplayOrder(false)
  };

  const updateLinksOrder = (dataIndex, rowData) => {
    let rowIndex = rowData.rowIndex;
    let selectedRow = data[rowIndex]
    setUpdateLinks(false)
    setopenDialog(true);
    setSelRowData(selectedRow);
    setDisplayOrder(true)
  }

  const renderReportLinks = (dataIndex, rowData) => {
    let rowIndex = rowData.rowIndex;
    let selectedRow = data[rowIndex];
    if (selectedRow) {
      return (
        <a
          style={style} href={selectedRow.linkForDocumentation}
          target="_blank" rel="noopener noreferrer">
          {selectedRow.displayName} </a>
      )
    }
  }

  const adminColumns = [
    {
      name: "id",
      label: "S.No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "displayName",
      label: "Folder Name",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            renderReportLinks(dataIndex, rowData)
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Update",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            <EditIcon
              className={"cursor"}
              onClick={() => updateLinksDetails(dataIndex, rowData)}
            />
          );
        },
      },
    },
    {
      name: "order",
      label: "Update Order",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            <i class="fas fa-sort-amount-up cursor"
              aria-hidden="true"
              style={{ fontSize: '15px' }}
              onClick={() => updateLinksOrder(dataIndex, rowData)}
            ></i>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            <DeleteIcon
              className={"cursor"}
              onClick={() => deleteSelResources(dataIndex, rowData)}
            />
          );
        },
      },
    },
  ];

  const userColumns =   [
    {
      name: "id",
      label: "S.No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "displayName",
      label: "Folder Name",
      options: {
        customBodyRender: (dataIndex, rowData) => {
          return (
            renderReportLinks(dataIndex, rowData)
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    }]

  const title = React.createElement(
    "div",
    { className: classes.title },
    `Resources Links`
  );

  const newUser = React.createElement(
    "div",
    {
      className: "newAlert",
      onClick: () => {
        setopenDialog(true);
        setUpdateLinks(false);
        setDisplayOrder(false)
        utils.userTrackingInfo({
          region: activeRegion,
          actionType: 'Click',
          pageName: 'Other Feature',
          reportName: "Users",
          pageDetails: `Redirected from Users to Create new User`,
        });
      },
    },
    "Create New Resources Links"
  );


  const createNewUser = React.createElement("div", { className: "alertBtn" }, [
    <PlusCircleFill />,
    newUser,
  ]);

  const config = React.createElement("div", { className: "button" }, [
    title,
    createNewUser
  ]);

  const container = React.createElement("div", { className: "titleInfo" }, [
    oktaAuth && oktaAuth.adminType === "Contributor" ? config : null,
  ]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container item xs={12} spacing={3} className={classes.flexContainer}>
          {renderContentDialog()}
          <Grid item xs={12}>
            <div className={classes.folderList}>
              <DataTable
                data={data.length > 0 ? data.sort((function (a, b) { return a.order - b.order })) : []}
                loading={loading}
                title={container}
                columns={ oktaAuth && oktaAuth.adminType === "Contributor" ? adminColumns : userColumns }
                height={"400px"}
                margin={"0"}
                selectableRows={'single'}   
                width={true}
                error={errorMessage}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Resources;